import { useInView } from 'framer-motion';
import React, { useEffect, useRef } from 'react';

import Draggable from 'components/Draggable';
import { ArticleThumbnailType } from 'types/landingPage';

import * as S from './LandingArticles.styles';

export interface LandingArticlesProps extends ArticleThumbnailType {
  className?: string;
}

const defaultProps: Partial<LandingArticlesProps> = {};

const LandingArticles: React.FC<LandingArticlesProps> = ({
  className,
  featured,
  title,
  description,
}: LandingArticlesProps) => {
  const ref = useRef();
  const wrapperRef = useRef();
  const isInView = useInView(ref, { once: true });
  const isTitleVisible = useInView(wrapperRef, { amount: 0.4 });

  useEffect(() => {
    console.log(isInView);
  }, [isInView]);

  return (
    <S.Wrapper ref={wrapperRef} className={className}>
      <S.TitleWrapper>
        <S.StickyWrapper>
          <S.Title
            animate={{ opacity: isTitleVisible ? 1 : 0 }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <S.SubTitle animate={{ opacity: isTitleVisible ? 1 : 0 }}>
            {description}
          </S.SubTitle>
        </S.StickyWrapper>
      </S.TitleWrapper>
      <S.AnimationWrapper ref={ref}>
        <S.ScrollableSectionWrapper
          animate={{ x: isInView ? '0%' : '100%' }}
          transition={{ duration: 0.7 }}
        >
          <Draggable>
            <S.ArticlesWrapper>
              {featured.map((article, idx) => (
                <S.Article key={article.title + idx} data={article} />
              ))}
            </S.ArticlesWrapper>
          </Draggable>
        </S.ScrollableSectionWrapper>
      </S.AnimationWrapper>
    </S.Wrapper>
  );
};

LandingArticles.defaultProps = defaultProps;

export default LandingArticles;
