import React from 'react';

import { colors } from 'utils/styles/theme';

interface Props {
  className?: string;
  color?: string;
}

export default function arrowHead({ color = colors.green, className }: Props) {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 25 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.271475 2.32636L7.96072 9.73603C8.13373 9.9039 8.36878 9.99884 8.61431 10L16.0344 10C16.28 9.99884 16.515 9.9039 16.688 9.73603L24.3773 2.32636C24.5068 2.20202 24.595 2.04336 24.6308 1.87062C24.6666 1.69784 24.6483 1.51871 24.5783 1.35597C24.5083 1.19324 24.3897 1.05421 24.2375 0.956545C24.0854 0.858876 23.9065 0.806961 23.7237 0.807378H19.5907C19.4694 0.8071 19.3493 0.829839 19.2371 0.874343C19.125 0.918847 19.0231 0.984238 18.9371 1.06672L12.2859 7.46219L5.64434 1.06672C5.55841 0.984238 5.45648 0.918847 5.34431 0.874343C5.23219 0.829839 5.11205 0.8071 4.99075 0.807378H0.910645C0.729035 0.809693 0.55218 0.863552 0.40224 0.962286C0.252251 1.06097 0.135857 1.20018 0.0676149 1.36236C-0.00062719 1.52454 -0.0176883 1.70251 0.0185954 1.874C0.054831 2.04549 0.142824 2.20285 0.271475 2.32636Z"
        fill={color}
      />
    </svg>
  );
}
