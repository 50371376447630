import { motion } from 'framer-motion';
import styled from 'styled-components';

import { colors } from 'utils/styles/theme';

export const DraggableWrapper = styled.div`
  width: 100%;
  position: relative;
  /* overflow-x: hidden; */
`;

export interface ScrollbarWrapperProps {
  position: 'bottom' | 'top';
}
export const ScrollbarWrapper = styled.div<ScrollbarWrapperProps>`
  width: 100%;
  overflow: hidden;
  position: absolute;
  ${({ position }) => position}: 0;
`;

export const Scrollbar = styled(motion.div)`
  width: 200%;
  height: 1px;
  background: linear-gradient(
    90deg,
    ${colors.white} 50%,
    ${colors.white}20 50%
  );
  margin-left: -100%;
`;

export const DraggableInner = styled(motion.div)`
  display: inline-flex;
`;
