import { axiosInstance } from 'utils/cms/api';

export const submitContactRequest = async message => {
  // /api/contact-requests
  const response = await axiosInstance.post('/api/contact-requests', {
    data: { message },
  });

  return response;
};
