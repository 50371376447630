import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';

export const Wrapper = styled(motion.div)`
  white-space: normal;
  pointer-events: all;
`;

export const ArticleData = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  ${setTypography('heading3')}
`;

export const Data = styled.div`
  ${setTypography('smallBody')}
  display: flex;
  justify-content: space-between;
  margin: 5px 0 7px 0;
`;

export const Description = styled.div`
  ${setTypography('body')}
`;

export const Thumbnail = styled.img`
  width: 100%;
  object-fit: cover;
  user-select: none;
  user-drag: none;

  /* ${mediaDesktop(css`
    width: 50rem;
    height: 33rem;
  `)} */
`;
