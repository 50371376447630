import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import ArticleCard from 'components/ArticleCard';
import SubtitleCmp from 'components/Subtitle';
import TitleCmp from 'components/Title';
import { grid, MARGIN, setTypography } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';

export const Wrapper = styled.div`
  grid-column: 1/-1;

  ${grid()}
  /* overflow-x: hidden; */
  grid-template-rows: min-content min-content auto;
  padding: 20vh 0;
`;

export const TitleWrapper = styled(motion.div)`
  grid-column: 2/-2;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  overflow-x: unset;
  height: 60vh;
`;

export const SubTitle = styled(motion.div)`
  ${setTypography('subtitle')}
  grid-column: 4/-4;
  display: none;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 60px;
  margin: auto;

  ${mediaDesktop(css`
    display: block;
  `)}
`;

export const StickyWrapper = styled(motion.div)`
  position: sticky;
  top: 50%;
`;

export const Title = styled(TitleCmp)`
  grid-column: 2/-2;
  text-align: center;
  ${setTypography('heading1')}
  margin-bottom: 15px;
`;

export const Subtitle = styled(SubtitleCmp)`
  grid-column: 2/-2;
  text-align: center;
  ${setTypography('subtitle')}
  margin-bottom: 10vh;
`;

export const ScrollableSectionWrapper = styled(motion.div)`
  cursor: pointer;
  overflow-x: hidden;
  width: 100vw;
  /* overflow-x: hidden; */
  padding: 10vh 0 10vh 0;
`;

export const ArticlesWrapper = styled.div`
  pointer-events: none;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;
`;

export const Article = styled(ArticleCard)`
  margin-left: 8vw;
  width: 68vw;

  &:first-child {
    margin-left: calc(10vw + 3vw);
  }

  &:last-child {
    margin-right: calc(10vw + 3vw);
  }

  ${mediaDesktop(`
    margin-left: ${MARGIN};
    width: 26vw;

    &:first-child {
      margin-left: ${MARGIN};
    }

    &:last-child {
      margin-right: ${MARGIN};
    }
  `)}
`;

export const AnimationWrapper = styled.div`
  overflow-x: hidden;
  width: 100%;
  grid-column: 1/-1;
`;
