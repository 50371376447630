import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import Input from 'components/Input';
import SubtitleCmp from 'components/Subtitle';
import TitleCmp from 'components/Title';
import { MARGIN, setTypography } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Title = styled(TitleCmp)`
  margin-bottom: 5vh;
  grid-column: 2/-2;
  text-align: center;
  ${setTypography('heading1')}
  grid-column: 1/-1;
  z-index: 2;
`;

export const Row = styled(motion.div)`
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  /* margin-bottom: 3vh; */

  ${mediaDesktop(css`
    align-items: flex-start;
    width: 100%;
    margin-bottom: 0;
  `)}
`;

export const Subtitle = styled(SubtitleCmp)`
  grid-column: 2/-2;
  text-align: center;
  ${setTypography('subtitle')}
  margin-bottom: 10vh;

  ${mediaDesktop(css`
    display: none;
  `)}
`;

export const ContactInfo = styled.div`
  grid-column: 3/-3;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${mediaDesktop(
    css`
      flex-direction: row-reverse;
      justify-content: space-between;
    `
  )}
`;

export const Column = styled(motion.div)`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  padding-left: 5rem;
  /* align-items: center; */

  margin-bottom: 5vh;

  ${mediaDesktop(`
    width: 35vw;
    /* border-left: 2px solid #00e600; */
    padding-left: ${MARGIN};
    justify-content: flex-start;
    align-items: flex-start;

    margin: 5vh 0 10vh 0;

    /* &:last-child {
      margin-left: ${MARGIN};
    } */
  `)}
`;

export const Separator = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: ${colors.green};
  transform-origin: top;
`;

export const SectionTitle = styled.div`
  ${setTypography('heading3')}
  margin-bottom: 2vh;

  b {
    color: ${colors.green};
  }
`;

export const BookMeeting = styled(motion.div)`
  margin-bottom: 4vh;

  ${mediaDesktop(css`
    margin-bottom: 8vh;
  `)}
`;

export const ContactInput = styled(Input)`
  /* min-width: 300px; */
  width: 100%;
  height: 7vh;

  ${mediaDesktop(css`
    min-width: 300px;
  `)}
`;

export const CheckboxWrapper = styled.div`
  margin-top: 1vh;
  display: flex;
  align-items: center;

  p,
  a {
    display: inline;
  }
`;

export const Copy = styled.div`
  ${setTypography('smallBody')}
  margin-left: 2rem;
`;

export const ContactTitle = styled.div`
  strong {
    display: inline;
  }

  p {
    display: inline;
  }
`;

export const Contact = styled(motion.div)`
  /* margin: 5vh 0 10vh 0; */
  /* text-align: center; */
  ${setTypography('smallBody')}

  strong {
    color: ${colors.green};
    ${setTypography('heading3')}
  }

  h1 {
    ${setTypography('heading3')}
  }

  p {
    ${setTypography('button')}
    font-weight: 500;
  }

  h1 {
    margin-top: 2vh;

    &:first-child {
      margin: 0;
    }
  }

  ${mediaDesktop(css`
    margin: 0;
    text-align: start;
    /* padding: 5vh 0 10vh 0; */
    h1 {
      margin-top: 4vh;
    }
  `)}
`;

export const ContactInfoRow = styled(motion.div)`
  display: flex;
  flex-direction: column;

  > div {
    &:first-child {
      margin-bottom: 1rem;
    }

    &:last-child {
      margin-bottom: 2rem;
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  /* height: 5.5vh; */
  border-radius: 15px;
`;

export const SubmitButton = styled(motion.div)`
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5vw;

  background-color: ${colors.green};
  color: ${colors.mediumGreen};
  height: 100%;

  ${setTypography('button')} /* border-radius: 15px; */
  cursor: pointer;
`;
