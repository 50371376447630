import { HTMLMotionProps } from 'framer-motion';
import React from 'react';

import * as S from './Subtitle.styles';

export interface SubtitleProps {
  className?: string;
  children?: any;
}

const defaultProps: Partial<SubtitleProps> = {};

const Subtitle: React.FC<SubtitleProps & HTMLMotionProps<'div'>> = ({
  className,
  children,
  ...rest
}: SubtitleProps) => {
  return (
    <S.Wrapper className={className} {...rest}>
      {children}
    </S.Wrapper>
  );
};

Subtitle.defaultProps = defaultProps;

export default Subtitle;
