import React from 'react';

import Cta from 'components/Cta/Cta';
import Interactive from 'components/Interactive/Interactive';
import { useGlobalStore } from 'store';
import { CtaSectionType } from 'types/cms';

import * as S from './CtaSection.styles';

export interface CtaSectionProps {
  className?: string;
  data: CtaSectionType;
}

const defaultProps: Partial<CtaSectionProps> = {};

const CtaSection: React.FC<CtaSectionProps> = ({
  className,
  data,
}: CtaSectionProps) => {
  const { setIsSchedulingOpen } = useGlobalStore();
  return (
    <S.Wrapper className={className}>
      <S.DescriptionWrapper>
        <S.Title>{data.title}</S.Title>
        <S.Description>{data.copy}</S.Description>
        <Cta
          label={data.ctaLabel}
          onClick={() => {
            setIsSchedulingOpen(true);
          }}
        />
      </S.DescriptionWrapper>
      <S.ContactWrapper>
        <S.Avatar src={data.picture.url} alt={data.name} />
        <S.Info>
          <S.Name>{data.name}</S.Name>
          <S.Position>{data.position}</S.Position>
          <S.Links>
            {data.links.map(link => (
              <Interactive key={`cta-section-${link.label}`}>
                <S.Link href={link.href} target="_blank">
                  {link.label}
                </S.Link>
              </Interactive>
            ))}
          </S.Links>
        </S.Info>
      </S.ContactWrapper>
    </S.Wrapper>
  );
};

CtaSection.defaultProps = defaultProps;

export default CtaSection;
