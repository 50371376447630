import { useInView } from 'framer-motion';
import React, { useRef, useState } from 'react';

import CheckBox from 'components/CheckBox';
import {
  container,
  item,
  lineTransition,
} from 'components/ContactSection/ContactSection.animation';
import Cta from 'components/Cta';
import RichText from 'components/RichText';
import { useCopyStore, useGlobalStore } from 'store';
import { ContactType } from 'types/contact';
import { submitContactRequest } from 'utils/cms/rest';

import * as S from './ContactSection.styles';

export interface ContactSectionProps {
  className?: string;
  title: string;
  description?: string;
  contact: ContactType;
}

const defaultProps: Partial<ContactSectionProps> = {};

const ContactSection: React.FC<ContactSectionProps> = ({
  title,
  description,
  contact,
}: ContactSectionProps) => {
  const ref = useRef();
  const inputRef = useRef<HTMLInputElement>();
  const [legalAccepted, setLegalAccepted] = useState(false);
  const [isSendingContactRequest, setIsSendingContactRequest] = useState(false);
  const [contactRequestMessage, setContactRequestMessage] = useState('');
  const isVisible = useInView(ref, { amount: 0.4 });
  const { copy } = useCopyStore();
  const { setIsSchedulingOpen } = useGlobalStore();

  const onContactRequestSubmit = async () => {
    if (!isSendingContactRequest) {
      setIsSendingContactRequest(true);

      await submitContactRequest(contactRequestMessage);

      inputRef.current.value = copy.global.contact.response;

      setIsSendingContactRequest(false);
    }
  };

  const onContactRequestInputChange = ev => {
    setContactRequestMessage(ev.target.value);
  };

  const onInputFocus = () => {
    if (inputRef.current.value === copy.global.contact.response)
      inputRef.current.value = '';
  };

  return (
    <>
      <S.Title
        variants={item}
        animate={isVisible ? 'show' : 'hidden'}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {description && <S.Subtitle>{description}</S.Subtitle>}
      <S.ContactInfo>
        <S.Column
          ref={ref}
          variants={container(0.3)}
          animate={isVisible ? 'show' : 'hidden'}
        >
          <S.Separator
            variants={lineTransition}
            animate={isVisible ? 'show' : 'hidden'}
            transition={{ duration: 0.6 }}
          />
          <S.BookMeeting variants={item}>
            <S.SectionTitle
              dangerouslySetInnerHTML={{ __html: contact?.letsMeetCopy }}
            />
            <Cta
              label={contact?.cta.label}
              onClick={() => {
                setIsSchedulingOpen(true);
              }}
            />
          </S.BookMeeting>

          <S.Row variants={item}>
            <S.SectionTitle
              dangerouslySetInnerHTML={{ __html: contact.leaveContactCopy }}
            />
            <S.InputWrapper>
              <S.ContactInput
                onChange={onContactRequestInputChange}
                onFocus={onInputFocus}
                ref={inputRef}
                label="Contact label"
              />
              {legalAccepted && (
                <S.SubmitButton
                  whileHover={{ scale: 1.1 }}
                  onClick={onContactRequestSubmit}
                >
                  {isSendingContactRequest
                    ? copy.global.contact.progress
                    : copy.global.contact.send}
                </S.SubmitButton>
              )}
            </S.InputWrapper>
            <S.CheckboxWrapper>
              <CheckBox
                onClick={() => setLegalAccepted(!legalAccepted)}
                checked={legalAccepted}
              />
              <S.Copy>
                <RichText richText={contact.legalCopy}></RichText>
              </S.Copy>
            </S.CheckboxWrapper>
          </S.Row>
        </S.Column>
        <S.Column>
          <S.Separator
            variants={lineTransition}
            animate={isVisible ? 'show' : 'hidden'}
            transition={{ duration: 0.6 }}
          />
          <S.Row>
            <S.Contact
              variants={container(0.2)}
              animate={isVisible ? 'show' : 'hidden'}
            >
              {contact.contactInformation.map(info => (
                <S.ContactInfoRow key={info.title} variants={item}>
                  <S.ContactTitle>
                    <RichText richText={info.title} />
                  </S.ContactTitle>
                  <RichText richText={info.info} />
                </S.ContactInfoRow>
              ))}
            </S.Contact>
          </S.Row>
        </S.Column>
      </S.ContactInfo>
    </>
  );
};

ContactSection.defaultProps = defaultProps;

export default ContactSection;
