import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${colors.gray};
  color: ${colors.darkGreen};

  padding: 10vw 5vw;

  ${mediaDesktop(css`
    flex-direction: row;
    justify-content: center;
  `)}

  #interactive {
    transform-origin: left;
  }
`;

export const Title = styled.div`
  ${setTypography('heading1')}
`;

export const Description = styled.div`
  ${setTypography('body')}
  margin: 2rem 0;
`;

export const DescriptionWrapper = styled.div`
  margin-bottom: 5rem;

  ${mediaDesktop(css`
    width: 40vw;
    margin-right: 10vw;
  `)}
`;

export const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Avatar = styled.img`
  clip-path: circle(50% at center);
  width: 25rem;
`;

export const Info = styled.div`
  margin-left: 5rem;
`;

export const Name = styled.div`
  ${setTypography('heading3')}
`;

export const Position = styled.div`
  ${setTypography('label')}
`;

export const Links = styled.div`
  display: flex;
`;

export const Link = styled.a`
  ${setTypography('label')}
  color: ${colors.backgroundGreen};
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }
`;
