export const imgVariants = {
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const textAnimate = {
  enter: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 200,
  },
};
