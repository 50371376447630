const splitCharacters = (text: string) => {
  const regexp = /<b>(.*?)<\/b>/gm;

  const results = text.match(regexp);
  const modifiedResults = [];

  results.forEach(result => {
    let finalText: string | string[] = result.replace('<b>', '');
    finalText = finalText.replace('</b>', '');

    finalText = finalText.split('').map(char => `<p>${char}</p>`);
    finalText.push('</b>');
    finalText = ['<b>', ...finalText];

    modifiedResults.push(finalText.join(''));
  });
  let finalText = text;
  modifiedResults.forEach((copy, idx) => {
    finalText = finalText.replace(results[idx], copy);
  });

  return finalText;
};

export const displayAnimation = (key, text, style) => {
  splitCharacters(text);

  return {
    key: key,
    // initial: { opacity: 0 },
    // exit: { opacity: 0 },
    // animate: { opacity: 1 },
    style: style,
    dangerouslySetInnerHTML: {
      __html: splitCharacters(text),
    },
  };
};
