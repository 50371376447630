import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import HalfLogoCmp from 'components/HalfLogo';
import { setTypography } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
// import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  position: relative;
  ${setTypography('heading2')}/* border: 5px solid #ffff00; */
`;

export const Content = styled(motion.div)`
  height: 50px;
  /* background-color: red; */
  min-width: 14rem;
  height: 10.381rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShadowContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  visibility: hidden;
`;

export const HalfLogo = styled(HalfLogoCmp)<{ right?: boolean }>`
  width: 6rem;
  height: 100%;

  ${({ right }) =>
    right &&
    css`
      transform: rotate(180deg);
    `}
`;

export const Background = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.green};
  border-radius: 15px;
`;

export const BrackerWrapper = styled(motion.div)<{ right?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  width: 6rem;
  width: calc(50% - 6rem);
  height: 100%;

  display: flex;

  ${mediaDesktop(
    css`
      width: calc(50% - 6rem);
    `
  )}

  ${({ right }) =>
    right &&
    css`
      justify-content: flex-end;
      left: auto;
      right: 0;
    `}
`;

export const InitialAnimationBracketWrapper = styled(motion.div)<{
  right?: boolean;
}>`
  position: absolute;

  width: 100%;

  ${({ right }) =>
    right &&
    css`
      display: flex;
      justify-content: flex-end;
    `}
`;

export const InitialAnimationTextWrapper = styled(motion.div)``;

export const Text = styled(motion.div)<{ secondary?: boolean }>`
  white-space: nowrap;
  /* min-width: calc(100% - 12rem); */
  text-align: center;
  margin: 0;
  z-index: 2;

  ${mediaDesktop(css`
    margin: 0 0rem;
  `)}

  ${({ secondary }) =>
    secondary &&
    `
  ${mediaDesktop(css`
    margin: 0 5rem;
  `)}
  `}
`;

export const VisibleContent = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${Text} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 24rem);
    height: 100%;
  }
`;

// export const Wrapper = styled.div`
//   position: relative;
//   display: flex;
//   align-items: center;
//   white-space: nowrap;

//   ${setTypography('button')}

//   ${mediaDesktop(css`
//     ${setTypography('heading2')}
//   `)}
// `;

// export const ShadowContent = styled.div`
//   padding: 0 28.36px;
//   opacity: 0;
//   height: 45px;

//   svg {
//     width: 40px;
//     height: 40px;
//   }

//   ${mediaDesktop(css`
//     height: 90px;
//     padding: 0 48.37px;
//   `)}
// `;

// export const Content = styled(motion.div)`
//   svg {
//     width: 40px;
//     height: 40px;
//   }
// `;

// export const Background = styled(motion.div)`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   border-radius: 7.5px;
//   clip-path: circle(calc(0%) at center);
//   background-color: rgba(0, 230, 0, 0);
// `;

// export const VisibleContent = styled(motion.div)`
//   position: absolute;
//   left: 50%;
//   top: 50%;

//   display: flex;
//   align-items: center;
//   height: 45px;
//   transform: translate(-50%, -50%);

//   ${mediaDesktop(css`
//     height: 90px;
//   `)}
// `;

// export const SideWrapper = styled(motion.div)`
//   position: absolute;
//   left: 0;
//   top: 0;
//   height: 45px;
//   width: 50%;

//   &:last-child {
//     right: 0;
//     left: auto;
//   }

//   ${mediaDesktop(css`
//     height: 90px;
//   `)}
// `;

// export const Parenthesis = styled.div<{ right?: boolean }>`
//   position: absolute;
//   right: 0;
//   height: 100%;

//   ${({ right }) =>
//     right &&
//     css`
//       right: auto;
//       left: 0;
//     `}
// `;

// export const HalfLogo = styled(HalfLogoCmp)<{ right?: boolean }>`
//   padding-right: 3px;

//   height: 100%;
//   width: 100%;
//   width: 28.36px;

//   ${({ right }) =>
//     right &&
//     css`
//       transform: rotate(180deg);
//     `}

//   ${mediaDesktop(css`
//     width: 48.37px;
//   `)}
// `;
