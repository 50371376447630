import { useMotionValue, useScroll, useTransform } from 'framer-motion';
import React, { useEffect, useRef } from 'react';

import Meet from 'components/Icons/meet';
import { useGlobalStore, useWindowStore } from 'store';
import { LandingPageLandingSection } from 'types/landingPage';
import { displayAnimation } from 'utils/animation.props';
import { DARK_BG, LIGHT_BG } from 'utils/styles/theme';

import * as S from './LandingSection.styles';

export interface LandingSectionProps extends LandingPageLandingSection {
  className?: string;
}

const defaultProps: Partial<LandingSectionProps> = {};

const LandingSection: React.FC<LandingSectionProps> = ({
  className,
  ...props
}: LandingSectionProps) => {
  const ref = useRef();
  const { isTablet, isMobile } = useWindowStore();

  const { bgColorTransition, setIsSchedulingOpen } = useGlobalStore();

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['90%', 'start'],
  });

  // const xDesktopOffset = useMotionValue('calc(-100% - 5vw)');
  const YDesktopOffset = useTransform(
    scrollYProgress,
    [0.5, 0],
    ['-50%', '50%']
  );
  const xPosDesktop = useTransform(scrollYProgress, [1, 0.5], ['15vw', '5vw']);
  const yPosDesktop = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    ['50%', '50%', '0%']
  );

  const scale = useTransform(
    scrollYProgress,
    [1, 0.5, 0.3, 0],
    [1, 1, 0.65, 0.65]
  );
  const opacity = useTransform(scrollYProgress, [1, 0.7, 0.3, 0], [1, 1, 0, 0]);

  const backgroundTransitionStep = useTransform(
    scrollYProgress,
    [1, 0.7, 0],
    [LIGHT_BG, LIGHT_BG, DARK_BG]
  );

  const YMobileOffset = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    ['-100%', '-100%', '-50%']
  );

  const xPosMobile = useTransform(scrollYProgress, [1, 0], ['50%', '10vw']);
  const display = useTransform(opacity, val => (val <= 0 ? 'none' : 'block'));
  const xMobilepOffset = useTransform(scrollYProgress, [1, 0], ['0%', '5vw']);
  const yPosMobile = useMotionValue('90%');

  useEffect(() => {
    backgroundTransitionStep.on('change', val => {
      bgColorTransition.set(val);
    });
  }, []);

  const renderButton = (key, content2, style) => (
    <S.CtaWrapper
      key={key}
      style={style}
      onClick={() => {
        setIsSchedulingOpen(true);
      }}
    >
      <S.AnimatedLogoButton
        mainContent={props.firstStateCta.label}
        secondaryContent={content2}
        scrollYProgress={scrollYProgress}
      />
    </S.CtaWrapper>
  );

  return (
    <>
      {/* <S.Background /> */}
      <S.Wrapper ref={ref} className={className}></S.Wrapper>
      <S.Display
        {...displayAnimation('display', props.text, {
          opacity: opacity,
          display,
        })}
      />
      {isMobile || isTablet
        ? renderButton('mobile', <Meet />, {
            right: xPosMobile,
            top: yPosMobile,
            scale: scale,
            x: xMobilepOffset,
            y: YMobileOffset,
          })
        : renderButton('desktop', props.secondStateCta.label, {
            // left: '50%',
            // top: '50%',
            right: xPosDesktop,
            top: yPosDesktop,
            scale: scale,
            // x: xDesktopOffset,
            y: YDesktopOffset,
          })}
    </>
  );
};

LandingSection.defaultProps = defaultProps;

export default LandingSection;
