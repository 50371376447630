import { useInView, useScroll, useTransform } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  cardVariant,
  contentVariants,
  imgVariants,
  serviceNameVariant,
  transformOrigins,
} from 'containers/HomePage/sections/LandingServices/LandingServices.animations';
import { useGlobalStore, useWindowStore } from 'store';
import { ServiceType } from 'types/service';
import { isIOS } from 'u9/utils/platform';
import { LINKS } from 'utils/config';
import { DARK_BG, LIGHT_BG } from 'utils/styles/theme';

import * as S from './LandingServices.styles';

interface ServiceSectionType {
  name: string;
  description: string;
  service: ServiceType;
}

export interface LandingServicesProps {
  title: string;
  description: string;
  services: Array<ServiceSectionType>;
  className?: string;
}

const defaultProps: Partial<LandingServicesProps> = {};

const LandingServices: React.FC<LandingServicesProps> = ({
  title,
  // description,
  services,
  className,
}: LandingServicesProps) => {
  const wrapperRef = useRef();
  const titleWrapperRef = useRef();
  const { bgColorTransition } = useGlobalStore();
  const isInView = useInView(wrapperRef);

  const { scrollYProgress } = useScroll({
    target: wrapperRef,
    offset: ['90%', 'start'],
  });

  const { scrollYProgress: scrollYTitleProgress } = useScroll({
    target: titleWrapperRef,
    offset: ['90%', 'start'],
  });

  const scrollYTitle = useTransform(
    scrollYTitleProgress,
    [1, 0],
    ['50vh', '15vmax']
  );

  const scrollScale = useTransform(scrollYTitleProgress, [1, 0], [1.3, 1]);

  const backgroundTransitionStep = useTransform(
    scrollYProgress,
    [1, 0.7, 0.2, 0],
    [DARK_BG, DARK_BG, DARK_BG, LIGHT_BG]
  );

  useEffect(() => {
    backgroundTransitionStep.on('change', val => {
      if (isInView) bgColorTransition.set(val);
    });

    return () => {
      backgroundTransitionStep.destroy();
    };
  }, [isInView]);

  return (
    <S.Wrapper
      ref={wrapperRef}
      key="landing-process-services"
      className={className}
    >
      <S.TitleWrapper ref={titleWrapperRef}>
        <S.Title
          dangerouslySetInnerHTML={{ __html: title }}
          style={{ top: scrollYTitle, scale: scrollScale }}
        />
      </S.TitleWrapper>
      <S.Services>
        {services.map((service, idx) => (
          <Card key={service.name} service={service} idx={idx} />
        ))}
      </S.Services>
    </S.Wrapper>
  );
};

interface CardProps {
  service: ServiceSectionType;
  idx: number;
}

const Card: React.FC<CardProps> = ({ service, idx }: CardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { isTablet, isMobile } = useWindowStore();
  const { push, locale } = useRouter();
  const isTouch = useMemo(() => {
    return isTablet || isMobile;
  }, [isTablet, isMobile]);

  return (
    <S.Service
      onMouseEnter={() => setIsHovered(!(isTablet || isMobile))}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        push(`${LINKS[locale].ABOUT}?service=${service.service.slug}`, null, {
          locale: locale,
          shallow: true,
        });
      }}
      key={`service-list-${service.name}`}
    >
      <S.ServiceContent
        variants={cardVariant}
        initial={isIOS() ? 'entered' : {}}
        animate={isHovered ? 'entered' : 'left'}
        whileInView={isTouch ? 'entered' : {}}
        viewport={{ once: true }}
        transition={{ ease: 'linear' }}
        style={{
          transformOrigin:
            isTablet || isMobile ? 'center' : transformOrigins[idx],
        }}
      >
        <S.FrontCardWrapper>
          <S.VisibleImage
            variants={imgVariants}
            // initial="left"
            animate={isHovered ? 'entered' : 'left'}
            whileInView={isTouch ? 'entered' : {}}
            viewport={{ once: true }}
          >
            <S.Img
              src={service.service.icon.url}
              alt={service.service.icon.alternativeText}
              animate={{
                filter: isHovered
                  ? 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
                  : 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0))',
              }}
            />
            <S.VisibleServiceName
              variants={serviceNameVariant}
              animate={isHovered ? 'entered' : 'left'}
              whileInView={isTouch ? 'entered' : {}}
              viewport={{ once: true }}
            >
              {service.name}
            </S.VisibleServiceName>
          </S.VisibleImage>
          <S.Content
            variants={contentVariants}
            animate={isHovered ? 'entered' : 'left'}
            whileInView={isTouch ? 'entered' : {}}
            viewport={{ once: true }}
          >
            <S.ServiceName>{service.name}</S.ServiceName>
            <S.ServiceDescription>{service.description}</S.ServiceDescription>
          </S.Content>
        </S.FrontCardWrapper>
      </S.ServiceContent>
    </S.Service>
  );
};

LandingServices.defaultProps = defaultProps;

export default LandingServices;
