// eslint-disable
import { useMotionValue, useTransform } from 'framer-motion';
import { clamp } from 'lodash';
import React, { useRef } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import * as S from './Draggable.styles';

export interface DraggableProps {
  children: React.ReactNode;
  scrollbar?: 'bottom' | 'top';
  transition?: any;
}

export const Draggable: React.FunctionComponent<DraggableProps> = ({
  children,
  scrollbar,
  transition,
  ...rest
}) => {
  const x = useMotionValue(0);
  const indicator = useMotionValue(0);
  const progress = useTransform(indicator, i => `${clamp(i, 0, 100) / 2}%`);

  const wrapper = useRef<HTMLDivElement>(null);
  const dragBox = useRef<HTMLDivElement>(null);

  useEffectOnce(() => {
    const childWidth = dragBox.current.getBoundingClientRect().width;
    const wrapperWidth = wrapper.current.getBoundingClientRect().width;
    const maxDrag = childWidth - wrapperWidth;
    x.onChange(value => {
      indicator.set((-value / maxDrag) * 100);
    });
  });

  const scrollIndicator = (
    <S.ScrollbarWrapper position={scrollbar}>
      <S.Scrollbar style={{ x: progress }} />
    </S.ScrollbarWrapper>
  );

  const content = (
    <S.DraggableInner
      drag="x"
      ref={dragBox}
      dragConstraints={wrapper}
      style={{ x }}
      dragTransition={transition}
    >
      {children}
    </S.DraggableInner>
  );

  return (
    <S.DraggableWrapper {...rest} ref={wrapper}>
      {scrollbar && scrollIndicator}

      {content}
    </S.DraggableWrapper>
  );
};

export default Draggable;
