import { motion } from 'framer-motion';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { grid, MARGIN, setTypography } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  grid-column: 1/-1;
  ${grid()}
  padding: 20vh 0 20vh 0;
  z-index: 2;
`;

export const Services = styled(motion.div)`
  grid-column: 2/-2;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 8vh;

  ${mediaTablet(css`
    grid-row-gap: 10vw;
  `)}

  ${mediaDesktop(`
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${MARGIN};
    grid-row-gap: ${MARGIN};
    margin: 150px 0 200px 0;
  `)}
`;

export const Title = styled(motion.div)`
  b {
    color: ${colors.green};
  }

  p {
    display: inline;
    opacity: 0;
  }

  position: sticky;
  top: 2vh;
  text-align: center;
  ${setTypography('heading1')}

  display: inline-block;
`;

export const ServiceContent = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40rem;

  background-color: ${rgba(colors.mediumGreen, 0.4)};
  cursor: pointer;
`;

export const Service = styled(motion.div)`
  grid-column: 1/-1;

  ${mediaDesktop(
    css`
      grid-column: span 1;
    `
  )}
`;

export const FrontCardWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  border-radius: 15px;
`;

export const Img = styled(motion.img)`
  width: 10rem;
`;

export const VisibleImage = styled(motion.div)`
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ServiceName = styled(motion.div)`
  ${setTypography('heading3')}
  margin-bottom: 1.5rem;
`;

export const VisibleServiceName = styled(motion.div)`
  ${setTypography('heading3')}
  line-height: 10rem !important;
  font-size: 3rem !important;

  display: none;
  ${mediaDesktop(
    css`
      display: block;
    `
  )}
`;

export const ServiceDescription = styled(motion.div)`
  ${setTypography('body')}
  text-align: center;
`;

export const Content = styled(motion.div)`
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  top: 8rem;
  left: 50%;
  width: 85%;
`;

export const TitleWrapper = styled.div`
  grid-column: 2/-2;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 80dvh;
  margin-bottom: 10vh;

  ${mediaDesktop(css`
    height: 100dvh;
    margin-bottom: 0vh;
  `)};
`;
