import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${mediaDesktop(
    css`
      flex-direction: row;
      justify-content: space-between;
    `
  )}
`;

export const Stage = styled.div`
  width: 17.5vh;
  height: 15vh;
  margin-bottom: 2vh;
  margin-right: 2vw;

  ${mediaDesktop(
    css`
      width: 15vw;
      height: auto;
      /* margin-right: auto; */

      &:last-child {
        margin-right: 0;
      }
    `
  )}
`;

export const StageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transform: rotate(-90deg) translateY(-100%);
  transform-origin: top right;

  ${mediaDesktop(
    css`
      transform: none;
      align-items: flex-start;
    `
  )}
`;

export const StageTitle = styled.div<{ isActive: boolean }>`
  ${setTypography('body')}
  color: ${colors.white};
  margin-bottom: 0.5vh;
  width: 14vh;
  text-align: end;

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${colors.green};
      ${setTypography('heading2')}
      font-size: 3vh;

      ${mediaDesktop('font-size: 3.7rem')}
    `}

  ${mediaDesktop(
    css`
      text-align: start;
      width: auto;
    `
  )}
`;

export const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  width: 14vh;

  ${mediaDesktop(
    css`
      width: 100%;
      flex-direction: row;
    `
  )}
`;

export const Step = styled(motion.div)<{ isActive: boolean }>`
  position: relative;
  height: 20px;
  width: 100%;

  cursor: pointer;

  &:last-child {
    margin: 0 !important;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    transform: translateY(-50%);

    background-color: ${colors.white};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        background-color: ${colors.green};
      }
    `}
`;
