export const container = delay => ({
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: delay,
    },
  },
});

export const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

export const lineTransition = {
  hidden: { scale: 0 },
  show: { scale: 1 },
};
