import {
  MotionValue,
  useMotionTemplate,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import Interactive from 'components/Interactive/Interactive';
import { colors } from 'utils/styles/theme';

import * as S from './AnimatedLogoButton.styles';

export interface AnimatedLogoButtonProps {
  className?: string;
  scrollYProgress: MotionValue<number>;
  mainContent: any;
  secondaryContent: any;
}

const defaultProps: Partial<AnimatedLogoButtonProps> = {};

const INITIAL_ANIMATION_DELAY = 0.5;

const AnimatedLogoButton: React.FC<AnimatedLogoButtonProps> = ({
  className,
  scrollYProgress,
  mainContent,
  secondaryContent,
}: AnimatedLogoButtonProps) => {
  const [shouldRender, setShouldRender] = useState(false);
  const scroll = useTransform(scrollYProgress, [1, 0], [1, 0]);
  const widthTransform = useTransform(scrollYProgress, [1, 0.3], [0, 1]);
  const shadowContentMainRef = useRef<HTMLDivElement>();
  const shadowContentSecondaryRef = useRef<HTMLDivElement>();
  const [displayText, setDisplayText] = useState(mainContent);
  const width1 = useMotionValue(0);
  const additionalOffset = useTransform(scroll, [1, 0.5, 0], [12, 12, 0]);
  const textColor = useTransform(
    scroll,
    [1, 0.5, 0],
    [colors.white, colors.mediumGreen, colors.mediumGreen],
    { clamp: true }
  );
  const width = useMotionTemplate`calc(${width1}px + ${additionalOffset}rem)`;

  const clipPath = useTransform(
    scrollYProgress,
    [1, 0.5, 0.2, 0],
    ['100%', '0%', '100%', '100%']
  );

  const opacity = useTransform(scrollYProgress, [1, 0.5, 0.4, 0], [1, 1, 0, 0]);

  const bgClip = useTransform(scrollYProgress, [1, 0.5, 0.2, 0], [0, 0, 1, 1]);

  const leftParenthesis = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    ['0%', '100%', '0%']
  );

  const rightParenthesis = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    ['0%', '-100%', '0%']
  );

  const clipContentOnScroll = useTransform(
    clipPath,
    val => `circle(calc(${val}) at center)`
  );

  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    if (shadowContentMainRef.current?.offsetWidth) {
      setShouldRender(true);
    }
  }, []);

  useEffect(() => {
    scroll.on('change', val => {
      setDisplayText(val <= 0.5 ? secondaryContent : mainContent);
    });

    widthTransform.on('change', val => {
      width1.set(
        shadowContentMainRef.current.offsetWidth +
          val *
            (shadowContentSecondaryRef.current.offsetWidth -
              shadowContentMainRef.current.offsetWidth)
      );
    });

    return () => {
      scroll.clearListeners();
      widthTransform.clearListeners();
    };
  }, [scroll, secondaryContent, mainContent, widthTransform, width1]);

  useEffect(() => {
    resize();
  }, [width1]);

  const resize = () => {
    setDisplayText(scroll.get() <= 0.5 ? secondaryContent : mainContent);
    width1.set(
      shadowContentMainRef.current.offsetWidth +
        widthTransform.get() *
          (shadowContentSecondaryRef.current.offsetWidth -
            shadowContentMainRef.current.offsetWidth)
    );
  };

  return (
    <S.Wrapper className={className}>
      <Interactive scale={1.05}>
        {shouldRender && (
          <S.Content
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={{ width: width }}
          >
            <S.Background
              style={{
                scaleX: bgClip,
              }}
            />
            <S.BrackerWrapper style={{ x: leftParenthesis, opacity: opacity }}>
              <S.InitialAnimationBracketWrapper
                initial={{ x: window?.scrollY ? '0%' : '100%' }}
                animate={{ x: '0%' }}
                transition={{ delay: INITIAL_ANIMATION_DELAY }}
              >
                <S.HalfLogo />
              </S.InitialAnimationBracketWrapper>
            </S.BrackerWrapper>
            <S.InitialAnimationTextWrapper
              initial={{ clipPath: 'circle(0% at center)' }}
              animate={{ clipPath: 'circle(100% at center)' }}
              transition={{ delay: INITIAL_ANIMATION_DELAY }}
            >
              <S.Text
                style={{
                  clipPath: clipContentOnScroll,
                  color: textColor,
                }}
              >
                {displayText}
              </S.Text>
            </S.InitialAnimationTextWrapper>
            <S.BrackerWrapper
              style={{ x: rightParenthesis, opacity: opacity }}
              right
            >
              <S.InitialAnimationBracketWrapper
                initial={{ x: window?.scrollY ? '0%' : '-100%' }}
                animate={{ x: '0%' }}
                transition={{ delay: INITIAL_ANIMATION_DELAY }}
                right
              >
                <S.HalfLogo right />
              </S.InitialAnimationBracketWrapper>
            </S.BrackerWrapper>
          </S.Content>
        )}
      </Interactive>
      <S.ShadowContent ref={shadowContentMainRef}>
        <S.HalfLogo />
        <S.Text>{mainContent}</S.Text>
        <S.HalfLogo />
      </S.ShadowContent>
      <S.ShadowContent ref={shadowContentSecondaryRef}>
        <S.Text secondary={true}>{secondaryContent}</S.Text>
      </S.ShadowContent>
    </S.Wrapper>
  );
};

AnimatedLogoButton.defaultProps = defaultProps;

export default AnimatedLogoButton;
