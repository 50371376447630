import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.input`
  ${setTypography('body')}
  background: ${colors.white};
  color: ${colors.darkGreen};
  /* border: 1px solid ${colors.green}; */
  border-radius: 15px;
  padding: 0 15px;
`;
