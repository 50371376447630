// import { AnimatePresence } from 'framer-motion';
import { GetStaticProps } from 'next';
import React, { useEffect } from 'react';

import { transitionVariants } from 'components/TransitionBackground/TransitionBackground';
import LandingArticles from 'containers/HomePage/sections/LandingArticles';
import LandingContact from 'containers/HomePage/sections/LandingContact';
import LandingProcess from 'containers/HomePage/sections/LandingProcess';
import LandingSection from 'containers/HomePage/sections/LandingSection';
import LandingServices from 'containers/HomePage/sections/LandingServices';
import { HomePageDataProps } from 'types/landingPage';
import { queryCMS } from 'utils/cms/api';
import { landingPage } from 'utils/cms/gql';
import { ISR_TIMEOUT } from 'utils/config';

import * as S from './HomePage.styles';

export interface HomePageProps extends HomePageDataProps {
  className?: string;
}

const defaultProps: Partial<HomePageProps> = {};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const response = await queryCMS({
    query: landingPage({
      locale,
    }),
  });

  return {
    props: {
      sharedCopy: response.sharedCopy,
      pageData: response,
      data: response.landingPage,
      head: response.landingPage.head,
      process: response.process,
      contact: response.contactPage,
      footer: response.footer,
      cookies: response.cookieBar,
    },
    revalidate: ISR_TIMEOUT,
  };
};

const HomePage: React.FC<HomePageProps> = ({
  className,
  data,
  process,
  contact,
}: HomePageProps) => {
  const modulesList = {
    ComponentLandingPageLandingSection: LandingSection,
    ComponentLandingPageServicesSection: LandingServices,
    ComponentLandingPageProcessSection: LandingProcess,
    ComponentLandingPageArticles: LandingArticles,
    ComponentLandingPageContactSection: LandingContact,
    ComponentPageCtaSection: S.CtaSectionCmp,
  };

  useEffect(() => {
    const beforeUnload = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, []);

  const renderModules = data.sections.map((moduleData, idx) => {
    const moduleName: string = moduleData.__typename;
    const ModuleComponent = modulesList[moduleName];

    if (!ModuleComponent) return null;

    return (
      <ModuleComponent
        key={`${moduleName}${idx}`}
        {...moduleData}
        data={moduleData}
        process={process}
        contact={contact}
      />
    );
  });

  return (
    <S.Wrapper
      initial="initial"
      animate="animate"
      exit="exit"
      variants={transitionVariants}
      className={className}
    >
      {/* <TransitionBackground /> */}
      {renderModules}
    </S.Wrapper>
  );
};

HomePage.defaultProps = defaultProps;

export default HomePage;
