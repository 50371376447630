import React from 'react';

import ContactSection from 'components/ContactSection/ContactSection';
import { ContactType } from 'types/contact';

import * as S from './LandingContact.styles';

export interface LandingContactProps {
  className?: string;
  title: string;
  description: string;
  contact: ContactType;
}

const defaultProps: Partial<LandingContactProps> = {};

const LandingContact: React.FC<LandingContactProps> = ({
  className,
  title,
  description,
  contact,
}: LandingContactProps) => {
  return (
    <S.Wrapper className={className}>
      <ContactSection
        title={title}
        description={description}
        contact={contact}
      />
    </S.Wrapper>
  );
};

LandingContact.defaultProps = defaultProps;

export default LandingContact;
