import { AnimatePresence, useInView } from 'framer-motion';
import React, { useRef, useState } from 'react';

import ArrowHead from 'components/Icons/arrowHead';
import {
  imgVariants,
  textAnimate,
} from 'containers/HomePage/sections/LandingProcess/LandingProcess.animations';
import { useWindowStore } from 'store';
import { ProcessType } from 'types/process';

import * as S from './LandingProcess.styles';

export interface LandingProcessProps {
  className?: string;
  title: string;
  description: string;
  process: ProcessType;
}

const defaultProps: Partial<LandingProcessProps> = {};

const LandingProcess: React.FC<LandingProcessProps> = ({
  className,
  process,
  title,
  description,
}: LandingProcessProps) => {
  const { isTablet, isMobile } = useWindowStore();
  const [activeStage, setActiveStage] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const ref = useRef();
  const isTitleVisible = useInView(ref, { amount: 0.4 });

  const goNext = direction => () => {
    const nextStep = activeStep + direction;
    const curentSteps = process.process[activeStage].steps;
    const stages = process.process;

    if (nextStep >= curentSteps.length && stages.length > activeStage + 1) {
      setActiveStage(activeStage + 1);
      setActiveStep(0);
    } else if (nextStep < 0 && activeStage - 1 >= 0) {
      setActiveStep(process.process[activeStage - 1].steps.length - 1);
      setActiveStage(activeStage - 1);
    } else if (nextStep < curentSteps.length && nextStep >= 0) {
      setActiveStep(nextStep);
    }
  };

  const renderDesktopProcess = () => (
    <>
      <S.Controls>
        <S.Arrow onClick={goNext(-1)} left>
          <ArrowHead />
        </S.Arrow>
        <S.Arrow onClick={goNext(1)} right>
          <ArrowHead />
        </S.Arrow>
      </S.Controls>
      <S.StageWrapper key={`stage-${process.process[activeStage].title}`}>
        <S.Title>{process.process[activeStage].title}</S.Title>
        <S.StepsWrapper>
          <S.StepWrapper
            key={`stage-${process.process[activeStage].title}-step-${process.process[activeStage].steps[activeStep].stepTitle}`}
            variants={textAnimate}
            initial="exit"
            animate="enter"
            exit="exit"
          >
            <S.Title>
              {process.process[activeStage].steps[activeStep].stepTitle}
            </S.Title>
            <S.Description>
              {process.process[activeStage].steps[activeStep].stepDescription}
            </S.Description>
            <S.Separator>
              <S.ArrowPointer>
                <ArrowHead />
              </S.ArrowPointer>
            </S.Separator>
          </S.StepWrapper>
        </S.StepsWrapper>
        <AnimatePresence>
          {process.process[activeStage].steps[activeStep]?.images.map(
            (image, idx) => {
              return (
                <S.Image
                  variants={imgVariants}
                  initial="exit"
                  animate="enter"
                  exit="exit"
                  key={`image-${process.process[activeStage].title}-${process.process[activeStage].title}-${idx}`}
                  id={`image-${idx}`}
                  src={image.url}
                  alt={
                    image.alternativeText ||
                    process.process[activeStage].steps[activeStep].stepTitle
                  }
                />
              );
            }
          )}
        </AnimatePresence>
        <S.ProcessPagination
          onClick={item => {
            setActiveStage(item.stage);
            setActiveStep(item.step);
          }}
          process={process}
          activeItem={{ stage: activeStage, step: activeStep }}
        />
      </S.StageWrapper>
    </>
  );

  const renderMobile = () =>
    process.process.map(stage => (
      <S.StageWrapper key={`stage-${stage.title}`}>
        <S.Title>{stage.title}</S.Title>
        <S.StepsWrapper>
          {stage.steps.map(step => (
            <S.StepWrapper
              // isVisible={isMobile || activeStep === stepIdx}
              key={`stage-${stage.title}-step-${step.stepTitle}`}
            >
              <S.Title>{step.stepTitle}</S.Title>
              <S.Description>{step.stepDescription}</S.Description>
              <S.Separator>
                <S.ArrowPointer>
                  <ArrowHead />
                </S.ArrowPointer>
              </S.Separator>
            </S.StepWrapper>
          ))}
        </S.StepsWrapper>
      </S.StageWrapper>
    ));

  return (
    <S.Wrapper key="landing-process-section" className={className} ref={ref}>
      <S.TitleWrapper>
        <S.StickyWrapper>
          <S.Title
            animate={{
              opacity: isMobile || isTablet || isTitleVisible ? 1 : 0,
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <S.SubTitle animate={{ opacity: isTitleVisible ? 1 : 0 }}>
            {description}
          </S.SubTitle>
        </S.StickyWrapper>
      </S.TitleWrapper>
      <S.ProcessesWrapper>
        {isTablet || isMobile ? renderMobile() : renderDesktopProcess()}
      </S.ProcessesWrapper>
    </S.Wrapper>
  );
};

LandingProcess.defaultProps = defaultProps;

export default LandingProcess;
