import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import ProcessPaginationCmp from 'components/ProcessPagination/ProcessPagination';
import TitleCmp from 'components/Title';
import { grid, setTypography } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  min-height: calc(100dvh - 140px);
  /* overflow-x: hidden; */

  grid-column: 1/-1;

  ${grid()}

  grid-template-rows: min-content min-content auto;

  ${mediaDesktop(
    css`
      padding-bottom: 6rem;
    `
  )}
`;

export const TitleWrapper = styled(motion.div)`
  grid-column: 2/-2;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */

  height: 15vm;

  ${mediaDesktop(css`
    height: 70vh;
  `)}
`;

export const StickyWrapper = styled(motion.div)`
  position: sticky;
  top: 40%;
  /* transform: translateY(-50%); */
`;

export const Title = styled(TitleCmp)`
  /* grid-column: 2/-2; */
  text-align: center;
  ${setTypography('heading1')}
`;

export const ProcessesWrapper = styled.div`
  position: relative;
  flex: 1;
  grid-column: 2/-2;

  ${mediaDesktop(css`
    grid-column: 1/-1;
  `)}
`;

export const StageWrapper = styled.div`
  ${Title} {
    ${setTypography('heading2')}
    margin-bottom: 1rem;

    ${mediaDesktop(css`
      font-weight: 500;
      font-size: 2.8rem;
      grid-column: 3 / span 5;
      grid-row: 2;
      text-align: start;
    `)}
  }

  margin-bottom: 4rem;

  ${mediaDesktop(css`
    display: grid;
    grid-template-columns: 20px repeat(12, 1fr) 20px;
    grid-template-rows: 1fr min-content 1fr 1fr;
  `)}
`;

export const StepsWrapper = styled.div`
  ${Title} {
    ${setTypography('heading3')}

    ${mediaDesktop(css`
      ${setTypography('heading2')}
    `)}
  }

  padding: 1rem 0;
  border: 1px dashed ${colors.green};
  border-radius: 15px;

  ${mediaDesktop(
    css`
      border: none;
      grid-area: 3 / 3 / span 2 / span 5;
    `
  )}
`;

export const Separator = styled.div`
  display: flex;
  justify-content: center;

  margin: 2.5rem 0;
  /* height: 1px; */

  ${mediaDesktop(
    css`
      display: none;
    `
  )}
`;

export const StepWrapper = styled(motion.div)`
  width: 80%;
  margin: auto;

  &:last-child {
    ${Separator} {
      display: none;
    }
  }

  ${mediaDesktop(css`
    margin: 0;
  `)}
`;

export const Description = styled.div`
  ${setTypography('body')}
  text-align: center;

  ${mediaDesktop(css`
    text-align: start;
  `)}
`;

export const SubTitle = styled(motion.div)`
  ${setTypography('subtitle')}
  grid-column: 4/-4;
  display: none;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 6rem;
  width: 70%;
  margin: auto;

  ${mediaDesktop(css`
    display: block;
  `)}
`;

export const Image = styled(motion.img)`
  /* position: absolute; */
  display: none;
  width: 100%;
  height: auto;
  display: block;

  ${mediaDesktop(css`
    grid-column: 9 / span 4;
    grid-row: 1 / -1;

    width: 63rem;
    height: 63rem;
  `)}
`;

export const Controls = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;

  justify-content: space-between;
  width: 95vw;

  ${mediaDesktop(css`
    display: flex;
  `)}
`;
export const Arrow = styled.div<{ left?: boolean; right?: boolean }>(
  ({ left }) => css`
    transform: rotate(${left ? '90deg' : '-90deg'});
    cursor: pointer;
    width: 3vw;
    pointer-events: all;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      transform: rotate(${left ? '90deg' : '-90deg'}) scale(1.3);
      transition: all 0.2s ease-in;
    }
  `
);

export const ArrowPointer = styled.div`
  height: 2rem;
`;

export const ProcessPagination = styled(ProcessPaginationCmp)`
  grid-column: 3/-3;
  display: flex;
  justify-content: space-between;

  max-width: 2200px;
  margin-top: 4rem;
`;
