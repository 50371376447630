import React from 'react';

import * as S from './Input.styles';

export interface InputProps {
  className?: string;
  onChange: (ev) => void;
  onFocus: () => void;
  label: string;
}

const defaultProps: Partial<InputProps> = {};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, onChange, onFocus, label }, ref) => {
    return (
      <S.Wrapper
        ref={ref}
        type="text"
        aria-label={label}
        className={className}
        onChange={onChange}
        onFocus={onFocus}
      />
    );
  }
);

Input.defaultProps = defaultProps;
Input.displayName = 'input';

export default Input;
