import { rgba } from 'polished';

import { colors } from 'utils/styles/theme';

export const imgVariants = {
  entered: {
    top: 0,
    left: '50%',
    y: '-50%',
    x: '-50%',
    scale: 1.2,
  },
  left: {
    top: '50%',
    left: '50%',
    x: '-50%',
    y: '-50%',
  },
};

export const imgVariantsMobile = {
  entered: {
    // top: 0,
    left: '50%',
    y: '0',
    x: '-50%',
    // scale: 1.2,
  },
  left: {
    // top: '50%',
    left: '50%',
    x: '-50%',
    y: '-50%',
  },
};

export const contentVariants = {
  entered: {
    opacity: 1,
    left: '50%',
    x: '-50%',
    transition: {
      delay: 0.2,
    },
  },
  left: {
    opacity: 0,
    left: '50%',
    x: '-50%',
  },
};

export const cardVariant = {
  entered: {
    // scale: 1.1,
    backgroundColor: rgba(colors.mediumGreen, 0.85),
  },
  left: {
    scale: 1,
  },
};

export const serviceNameVariant = {
  entered: {
    opacity: 0,
    height: 0,
    scale: 1.2,
    transition: { duration: 0.1 },
  },
  left: {
    opacity: 1,
    height: 'auto',
    transition: { duration: 0.1 },
  },
};

export const transformOrigins = [
  'bottom right',
  'bottom',
  'bottom left',
  'top right',
  'top',
  'top left',
];
