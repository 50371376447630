import React from 'react';

import { useWindowStore } from 'store';
import { ProcessType } from 'types/process';

import * as S from './ProcessPagination.styles';

export interface ProcessPaginationProps {
  className?: string;
  process: ProcessType;
  activeItem: { stage: number; step: number };
  onClick: (item: { stage: number; step: number }) => void;
}

const defaultProps: Partial<ProcessPaginationProps> = {};

const ProcessPagination: React.FC<ProcessPaginationProps> = ({
  className,
  process,
  activeItem,
  onClick,
}: ProcessPaginationProps) => {
  const { isMobile, isTablet } = useWindowStore();
  return (
    <S.Wrapper className={className}>
      {process.process.map((stage, stageIdx) => (
        <S.Stage key={stage.title}>
          <S.StageContent>
            <S.StageTitle isActive={activeItem.stage === stageIdx}>
              {stage.title}
            </S.StageTitle>
            <S.Steps>
              {stage.steps.map((step, stepIdx) => (
                <S.Step
                  key={step.stepTitle}
                  isActive={
                    activeItem.stage === stageIdx && activeItem.step === stepIdx
                  }
                  onClick={() => onClick({ stage: stageIdx, step: stepIdx })}
                  style={{
                    width: `${100 / stage.steps.length}%`,
                    marginLeft:
                      isMobile || isTablet ? `${20 / stage.steps.length}%` : 0,
                    marginRight:
                      isMobile || isTablet ? 0 : `${20 / stage.steps.length}%`,
                  }}
                ></S.Step>
              ))}
            </S.Steps>
          </S.StageContent>
        </S.Stage>
      ))}
    </S.Wrapper>
  );
};

ProcessPagination.defaultProps = defaultProps;

export default ProcessPagination;
