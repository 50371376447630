import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import AnimatedLogoButtonCmp from 'components/AnimatedLogoButton';
import Title from 'components/Title';
import { MARGIN, setVh } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: relative;
  grid-column: 1/-1;
  height: ${setVh(100)};

  ${mediaDesktop(css`
    height: calc(120vh - min(106px, 10vh));
    grid-column: 1/6;
  `)};
`;

export const Display = styled(Title)`
  position: fixed;

  top: 50%;
  left: ${MARGIN};
  right: ${MARGIN};
  transform: translateY(-50%);
  grid-column: 2/-2;

  ${mediaTablet(css`
    left: 10vw;
    right: 10vw;
  `)}

  ${mediaDesktop(
    `
      padding-left: 0;
      width: 40vw;
      left: ${MARGIN};
      right: auto;
    `
  )}
`;

export const AnimatedLogoButton = styled(AnimatedLogoButtonCmp)`
  cursor: pointer;

  transform: translateX(50%);

  ${mediaDesktop(css`
    transform: translateY(-50%);
  `)}
`;

export const CtaWrapper = styled(motion.div)`
  position: fixed;
  z-index: 9;

  ${mediaDesktop(css`
    transform-origin: top right;
  `)}
`;

export const Background = styled.div`
  background: linear-gradient(
    180deg,
    ${colors.mediumGreen} 0%,
    ${colors.backgroundGreen} 18%,
    ${colors.backgroundGreen} 36.98%,
    ${colors.backgroundGreen} 64.58%,
    ${colors.mediumGreen} 100%
  );

  position: absolute;
  top: min(106px, 10vh);
  left: 0;
  right: 0;
  bottom: 0;
`;
