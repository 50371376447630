import { useRouter } from 'next/router';
import React from 'react';

import { ArticleType } from 'types/article';

import * as S from './ArticleCard.styles';

export interface ArticleCardProps {
  className?: string;
  data: ArticleType;
}

const defaultProps: Partial<ArticleCardProps> = {};

const ArticleCard: React.FC<ArticleCardProps> = ({
  className,
  data,
}: ArticleCardProps) => {
  const { push } = useRouter();

  return (
    <S.Wrapper
      className={className}
      whileHover={{ scale: 1.1 }}
      onTap={() => {
        push(`/blog/${data.slug}`, null, { scroll: false });
      }}
    >
      <S.Thumbnail
        loading="lazy"
        src={data.hero.url}
        alt={data.hero.alternativeText}
        draggable="false"
      />
      <S.ArticleData>
        <S.Title>{data.title}</S.Title>
        <S.Data>
          <p>{data.timeToRead} read</p>
          <p>20-02-2022</p>
        </S.Data>
        <S.Description>{data.shortDescription}</S.Description>
      </S.ArticleData>
    </S.Wrapper>
  );
};

ArticleCard.defaultProps = defaultProps;

export default ArticleCard;
