import { motion } from 'framer-motion';
import styled from 'styled-components';

import CtaCmp from 'components/Cta';
import CtaSection from 'components/CtaSection/CtaSection';
import { grid, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  position: relative;

  display: grid;
  ${grid()}
  ${setTypography('display')}
  min-height: calc(100dvh - min(106px, 10vh));

  color: ${colors.white};
`;

export const Cta = styled(CtaCmp)`
  position: sticky;
  top: 80vh;
`;

export const CtaSectionCmp = styled(CtaSection)`
  grid-column: 1/-1;
  margin: 3vh 0;
`;

export const Bg = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: red;
  pointer-events: none;

  z-index: 100;
`;
